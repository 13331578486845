import { PROCESSING } from "../actions/processing";

export const InitialState = {
  // main data
  mainData: [
    {
      step: 1,
      question_title: "Укажи свои параметры",
      question_title_desc: "(отправные точки)",
      question_desc:
        "Собираем данные, чтобы рассчитать индекс массы тела и определить интенсивность тренировок. Чем больше информации, тем ближе цель!",
      images: [],
      answers: [],
    },
    {
      step: 2,
      question_title: "Твоя цель - это",
      question_title_desc: "",
      question_desc: "Гибкость, рельеф, стройность или тонус",
      images: [
        {
          id: 4,
          image: "gender_3.jpg",
          mobile: "gender_3_mobile.jpg",
        },
        {
          id: 2,
          image: "gender_1.jpg",
          mobile: "gender_1_mobile.jpg",
        },
        {
          id: 1,
          image: "gender_2.jpg",
          mobile: "gender_2_mobile.jpg",
        },
        {
          id: 3,
          image: "gender_4.jpg",
          mobile: "gender_4_mobile.jpg",
        },
      ],
      answers: [
        {
          id: 4,
          title: "Гибкость ",
          subtitle: "",
          desc: "Гибкость – улучшаем растяжку и увеличиваем подвижность в суставах всего тела. Занятия помогут избавиться от скованности и перенапряжения мышц, позволят почувствовать легкость в движениях.",
        },
        {
          id: 2,
          title: "Рельеф",
          subtitle: "",
          desc: "Рельеф – работаем над изменением структуры тела. Тренировка направлена на укрепление мускулатуры без увеличения объемов, но с заметной прорисовкой мышечных волокон.",
        },
        {
          id: 1,
          title: "Cтройность",
          subtitle: "",
          desc: "Стройность – создаем красивую и пропорционально сложенную фигуру. Тренировка направлена на активное сжигание калорий, ускорение обмена веществ и коррекцию форм.",
        },
        {
          id: 3,
          title: "Тонус",
          subtitle: "",
          desc: "Тонус – активизируем все группы мышц. Занятия направлены на укрепление сердечно-сосудистой системы, развитие координации и мышечного корсета.",
        },
      ],
    },
    // {
    //   step: 3,
    //   question_title: 'Где будешь тренироваться?',
    //   question_title_desc: '',
    //   question_desc: '',
    //   images: [
    //     {
    //       id: 1,
    //       image: 'home.jpg',
    //       mobile: ''
    //     },
    //     {
    //       id: 2,
    //       image: 'fitron-gym.jpg',
    //       mobile: ''
    //     }
    //   ],
    //   answers: [
    //     {
    //       id: 1,
    //       title: 'Дома',
    //       subtitle: 'с базовым набором инвентаря: коврик и гантели',
    //       desc: '',
    //     },
    //     {
    //       id: 2,
    //       title: 'В тренажерном зале',
    //       subtitle: '',
    //       desc: ''
    //     }
    //   ]
    // },
    {
      step: 3,
      question_title: "Сколько тренировок в неделю готов выполнять?",
      question_title_desc: "",
      question_desc: "",
      images: [],
      answers: [
        {
          id: 1,
          title: "1",
          subtitle: "",
          desc: "",
        },
        {
          id: 2,
          title: "2",
          subtitle: "",
          desc: "",
        },
        {
          id: 3,
          title: "3",
          subtitle: "",
          desc: "",
        },
        {
          id: 4,
          title: "4",
          subtitle: "",
          desc: "",
        },
        {
          id: 5,
          title: "5",
          subtitle: "",
          desc: "",
        },
      ],
    },
  ],

  genderData: [
    {
      id_str: "male",
      title: "Мужской",
    },
    {
      id_str: "female",
      title: "Женский",
    },
  ],

  // common
  step: 1,
  email: "",
  phone: "",

  // step 1
  gender: "male",
  age: null,
  height: null,
  weight: null,

  // step 2
  goalID: null,
  goalValue: null,

  // step 3
  placeID: null,
  placeValue: null,
  placeIDDefault: 2,
  placeValueDefault: "В тренажерном зале",

  // step 4
  countID: null,
  countValue: null,
};

// REDUCERS
export default function (state = InitialState, action) {
  switch (action.type) {
    case PROCESSING.CHANGE_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });

    case PROCESSING.SET_CONTACTS:
      return Object.assign({}, state, {
        email: action.email,
        phone: action.phone,
      });

    case PROCESSING.SET_STEP_1:
      return Object.assign({}, state, {
        gender: action.gender,
        age: action.age,
        height: action.height,
        weight: action.weight,
      });

    case PROCESSING.SET_STEP_2:
      return Object.assign({}, state, {
        goalID: action.id,
        goalValue: action.value,
      });

    case PROCESSING.SET_STEP_3:
      return Object.assign({}, state, {
        placeID: action.id,
        placeValue: action.value,
      });

    case PROCESSING.SET_STEP_4:
      return Object.assign({}, state, {
        countID: action.id,
        countValue: action.value,
      });

    default:
      return state;
  }
}
