import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import YouTube from 'react-youtube';
import ScrollArea from 'react-scrollbar';

import { getVideosList } from '../../actions/trainings';
import { formOfWord } from '../../utils/formOfWord';
import { getParams } from '../../utils/getParams';

import './styles/trainings.scss';

const mobileSize = 699;

class Trainings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      videoLoading: false,
      videoStatus: false,
      selectedVideo: null,
      videoID: null,
      week: null,
      day: null,
      videosOfDay: [],

      mobileVersion: window.innerWidth <= mobileSize ? true : false,
      mobileCurrentVideoID: null
    }

    this.video = null;
    this.videoBlock = null;
    this.scrollBlock = null;

    // for mobile
    this.mobileVideo = [];
  }

  componentDidMount() {
    this.props.getVideosList(window.location.pathname.split('/')[2]);

    window.addEventListener("resize", this.updateBlocks.bind(this));

    setTimeout(() => {
      document.querySelector('.trainings').classList.add('_show');
    }, 200)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.videos.length && this.props.videos.length) {
      let params = getParams(window.location);

      let selectedVideoID = +params.video;
      let week = +params.week;
      let day = +params.day;
      let videosOfDay = [];

      this.props.videos.forEach(el => {
        if (el.week === week && el.day === day) {
          el.list.forEach(item => videosOfDay.push(item))
        }
      });

      videosOfDay.forEach(item => {
        if (item.id === selectedVideoID) {
          this.setState({
            selectedVideo: item,
            videoID: item.id,
            week: week,
            day: day,
            videosOfDay: videosOfDay
          },() => {
            !this.state.mobileVersion && this.scrollToActiveVideo();
          })
        }
      })

      setTimeout(() => {
        if (this.state.selectedVideo === null) {
          this.setState({
            selectedVideo: false
          })
        }
      }, 200)
    }
  }

  // write videoYT in variables
  readyMainVideo(e) {
    this.video = e.target;

    this.setState({
      videoLoading: true,
    })
  }

  // write videoYT in variables on mobile
  readyMobileVideo(id, e) {
    let tempObj = {};

    tempObj.id = id;
    tempObj.video = e.target;

    this.mobileVideo.push(tempObj);
  }

  // play video (hide preview)
  playMainVideo() {
    this.video.playVideo();

    this.setState({
      videoStatus: true
    })

    // turn off video from list on mobile
    if (this.state.mobileCurrentVideoID && window.innerWidth < mobileSize) {
      this.endMobileVideo(this.state.mobileCurrentVideoID);
    }
  }

  // play video (hide preview) on mobile from list
  playMobileVideo(id) {
    if (this.state.videoStatus) {
      this.endMainVideo()
    }

    this.mobileVideo.forEach(el => {
      if (el.id === id) {
        el.video.playVideo();
      }
      if (el.id === this.state.mobileCurrentVideoID) {
        el.video.stopVideo();
        this.setState({
          mobileCurrentVideoID: null
        })
      }
    })

    this.setState({
      mobileCurrentVideoID: id
    })
  }

  // stop video (show preview)
  endMainVideo() {
    this.video.stopVideo();
    
    this.setState({
      videoStatus: false
    })
  }

  // stop video (show preview) on mobile
  endMobileVideo(id) {
    this.mobileVideo.forEach(el => {
      el.id === id && el.video.stopVideo();
    })

    this.setState({
      mobileCurrentVideoID: null
    })
  }

  // seleted video handler on desktop and mobile
  selectedVideo(video) {
    if (this.state.selectedVideo) {
      this.videoBlock.resetPlayer();
    }

    this.setState({
      selectedVideo: video,
      videoLoading: false,
      videoStatus: false,
      videoID: video.id
    })
  }

  // scroll to active element
  scrollToActiveVideo() {
    setTimeout(() => {
      let scrollTop = document.querySelectorAll('.videos__item._active');
      this.scrollBlock.scrollYTo(scrollTop[0].offsetTop);
    }, 200)
  }

  updateBlocks() {
    if (window.innerWidth < mobileSize) {
      this.setState({
        mobileVersion: true
      })
    } else {
      this.setState({
        mobileVersion: false
      })
    }
  }

  render() {

    const {
      videos,
      program,
      isExpired,
    } = this.props;

    const optsVideo = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // autoplay: 1,
        rel: 0,
        showinfo: 0
      }
    };
       
    return(
      <div className="trainings">
        {
          isExpired !== null
          ? isExpired === false
            ? window.innerWidth <= mobileSize && !this.state.videoLoading
              ? <div className="trainings__loader">
                  <img src={ require('../../static/images/loading.gif') } alt="" />
                </div>
              : null
            : null
          : null
        }
        {
          isExpired !== null
          ? isExpired === false
            ? <div className="container">
                <div className="trainings__main">
                  {
                    videos && this.state.selectedVideo
                    ? <div className="trainings__main__video">
                        <div className={`trainings__main__video__preview ${this.state.videoStatus && '_hidden'}`} style={{backgroundImage: `url(https://img.youtube.com/vi/${this.state.selectedVideo.youtube_video_id}/maxresdefault.jpg)`}}>
                          <button type="button" onClick={this.playMainVideo.bind(this)}></button>
                        </div>
                        <div className="trainings__main__video__frame">
                          <YouTube
                            ref={ (node) => this.videoBlock = node }
                            videoId={this.state.selectedVideo.youtube_video_id}
                            opts={optsVideo}
                            onReady={this.readyMainVideo.bind(this)}
                            onEnd={this.endMainVideo.bind(this)}
                          />
                        </div>
                      </div>
                    : <div className="trainings__main__video__error">
                        {
                          this.state.selectedVideo === false &&
                          <p>Выбранное видео не&nbsp;существует, выберите другое упражнение</p>
                        }
                      </div>
                  }
                  {
                    videos && this.state.selectedVideo
                    ? <div className="trainings__main__desc">
                        <h3>{this.state.selectedVideo.name}</h3>
                        {
                          program === 'old'
                          ? <p>{`${this.state.selectedVideo.cnt_iteration} ${formOfWord(this.state.selectedVideo.cnt_iteration,['подход','подхода','подходов'])} по ${this.state.selectedVideo.iteration}`}</p>
                          : <>
                              <p className="author-name">{this.state.selectedVideo.trainer_text}</p>
                              <p className="training-desc" dangerouslySetInnerHTML={{__html:this.state.selectedVideo.description}}/>
                            </>
                        }
                      </div>
                    : null
                  }
                </div>
                <div className="trainings__list">
                  <div className="trainings__list__header">
                    <div className="trainings__list__header__title">
                      <p>Все упражнения</p>
                    </div>
                    <div className="trainings__list__header__attention">
                      <img src={ require('../../static/images/icons/icon-time.svg') } alt="" />
                      <p>Разминка 10 мин.</p>
                    </div>
                  </div>

                  {/* mobile block with videos */}
                  {
                    this.state.mobileVersion && videos && this.state.videosOfDay
                    ? <div className="trainings__list__mobile videos-mobile">
                        {
                          this.state.videosOfDay.map((item, ind) => {
                            if (item && item.name) {
                              return (
                                <Fragment key={ind}>
                                  {
                                    program === 'new'
                                    ? item.is_main
                                      ? <p className="title-type">Основное упражнение</p>
                                      : <p className="title-type">Альтернативная тренировка</p>
                                    :  null
                                  }
                                  <div className={`videos-mobile__item ${this.state.mobileCurrentVideoID === item.id && '_hidden'}`} key={item.id}>
                                    <div className="videos-mobile__main">
                                      <div className="videos-mobile__main__preview" style={{backgroundImage: `url('https://img.youtube.com/vi/${item.youtube_video_id}/maxresdefault.jpg')`}}>
                                        <button type="button" onClick={ this.playMobileVideo.bind(this, item.id) }></button>
                                      </div>
                                      <div className="videos-mobile__main__video">
                                        <YouTube
                                          videoId={item.youtube_video_id}
                                          opts={optsVideo}
                                          onReady={this.readyMobileVideo.bind(this, item.id)}
                                          onEnd={this.endMobileVideo.bind(this, item.id)}
                                        />
                                      </div>
                                    </div>
                                    <div className="videos-mobile__info">
                                      <h3>{item.name}</h3>
                                      {
                                        program === 'old'
                                        ? <p>{`${item.cnt_iteration} ${formOfWord(item.cnt_iteration,['подход','подхода','подходов'])} по ${item.iteration}`}</p>
                                        : <>
                                            <p className="author-name">{item.trainer_text}</p>
                                            <p className="training-desc" dangerouslySetInnerHTML={{__html:item.description}} />
                                          </>
                                      }
                                    </div>
                                  </div>
                                </Fragment>
                              )
                            } else {
                              return null
                            }
                          })
                        }
                      </div>
                    : null
                  }

                  {/* desktop and tablet block with button on videos */}
                  {
                    !this.state.mobileVersion && videos && this.state.videosOfDay
                    ? <div className={`trainings__list__body ${program === 'new' && this.state.videosOfDay.length <= 3 ? '_right' : ''} videos`}>
                        <ScrollArea
                          speed={0.4}
                          className="area"
                          contentClassName="content"
                          horizontal={false}
                          minScrollSize={72}
                          ref={ (node) => this.scrollBlock = node }
                        >
                          {
                            this.state.videosOfDay.map((item, ind) => {
                              if (item && item.name) {
                                return (
                                  <Fragment key={ind}>
                                    {
                                      program === 'new'
                                      ? item.is_main
                                        ? <p className="title-type">Основное упражнение</p>
                                        : <p className="title-type">Альтернативная тренировка</p>
                                      :  null
                                    }
                                    <div className={`videos__item ${this.state.videoID === item.id && '_active'}`} key={item.id}>
                                      <button type="button" onClick={ this.selectedVideo.bind(this, item) }></button>
                                      <div className="videos__preview" style={{backgroundImage: `url('https://img.youtube.com/vi/${item.youtube_video_id}/maxresdefault.jpg')`}}></div>
                                      <div className="videos__info">
                                        <h3>{item.name}</h3>
                                        {
                                          program === 'old'
                                          ? <p>{`${item.cnt_iteration} ${formOfWord(item.cnt_iteration,['подход','подхода','подходов'])} по ${item.iteration}`}</p>
                                          : <p className="author-name">{item.trainer_text}</p>
                                        }
                                        
                                      </div>
                                    </div>
                                  </Fragment>
                                )
                              } else {
                                return null
                              }
                            })
                          }
                        </ScrollArea>
                      </div>
                    : null
                  }
                </div>
              </div>
            : <div className="container">
                <div className="trainings__locking">
                  <p>Программа тренировок доступна в&nbsp;течение месяца после покупки</p>
                </div>
              </div>
          : null 
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    trainings,
  } = state;

  return {
    ...trainings,
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getVideosList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Trainings)