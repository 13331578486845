import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NumberFormat from "react-number-format";
import axios from "axios";
import { FacebookShareButton, VKShareButton } from "react-share";

import { openModal, changeNameModal, setErrorText } from "../../actions/modal";

import { validateEmail } from "../../utils/validateEmail";
import share from "../../static/images/share.jpg";

import "./styles/finish.scss";

const url = `${window.location.origin}`;
const title = `Получи программу тренировок за 30 секунд!`;
const description = `Ответь на вопросы, чтобы рассчитать индекс массы тела, определить интенсивность тренировок и составить уникальную программу тренировок. Чем больше информации, тем ближе цель!`;
const shareImg = share;

const mobileMax = 699;

class Finish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subStep: 1,
      wantBlock: null, // null, start, finish
      btnLoading: false,
      wantButtonLoading: false,

      email: "",
      phone: "",
      agreement: "",

      emailError: false,
      phoneError: false,
      agreementError: false,

      touchEmail: false,
      touchPhone: false,

      emailErrorText: "",
      phoneErrorText: "",
      agreementErrorText: "",
    };

    this.emailInput = null;
  }

  componentDidMount() {
    if (window.location.pathname === "/finish" && this.props.step !== 3) {
      window.location = `${window.location.origin}`;
    }

    this.emailInput.focus();
  }

  onChangeEmail(event) {
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        if (!this.state.touchEmail) {
          this.setState({
            touchEmail: true,
          });
        }
      }
    );
  }

  onFocusEmail() {
    if (!this.state.touchEmail) {
      return false;
    } else {
      this.setState({
        emailError: false,
        emailErrorText: "",
      });
    }
  }

  onBlurEmail() {
    this.checkValidEmail();
  }

  onChangePhone(values) {
    this.setState(
      {
        phone: values.formattedValue,
      },
      () => {
        if (!this.state.touchPhone) {
          this.setState({
            touchPhone: true,
          });
        }
      }
    );
  }

  onFocusPhone() {
    if (!this.state.touchPhone) {
      return false;
    } else {
      this.setState({
        phoneError: false,
        phoneErrorText: "",
      });
    }
  }

  onBlurPhone() {
    this.checkValidPhone();
  }

  handleAgreement() {
    this.setState(
      {
        agreement: !this.state.agreement,
      },
      () => {
        this.checkValidAgreement();
      }
    );
  }

  checkValidEmail() {
    if (this.state.email === "") {
      this.setState({
        emailError: true,
        emailErrorText: "Введите e-mail",
      });
    } else if (this.state.email !== "" && !validateEmail(this.state.email)) {
      this.setState({
        emailError: true,
        emailErrorText: "Введен некорректный e-mail",
      });
    } else {
      this.setState({
        emailError: false,
        emailErrorText: "",
      });
    }
  }

  checkValidPhone() {
    if (this.state.phone === "") {
      this.setState({
        phoneError: true,
        phoneErrorText: "Введите телефон",
      });
    } else if (this.state.phone.length !== 12) {
      this.setState({
        phoneError: true,
        phoneErrorText: "Введен некорректный телефон",
      });
    } else {
      this.setState({
        phoneError: false,
        phoneErrorText: "",
      });
    }
  }

  checkValidAgreement() {
    if (!this.state.agreement) {
      this.setState({
        agreementError: true,
        agreementErrorText: "Необходимо твое согласие",
      });
    } else {
      this.setState({
        agreementError: false,
        agreementErrorText: "",
      });
    }
  }

  sendFormContacts() {
    if (
      this.state.email &&
      this.state.phone &&
      this.state.agreement &&
      !this.state.emailError &&
      !this.state.phoneError &&
      !this.state.agreementError
    ) {
      this.setState({
        btnLoading: true,
      });

      let data = {
        gender: this.props.gender,
        age: this.props.age,
        weight: this.props.weight,
        height: this.props.height,
        goal: this.props.goalID,
        type_train: this.props.placeID || this.props.placeIDDefault,
        count: this.props.countID,
        email: this.state.email,
        phone: this.state.phone,
        ispayment: 0,
        return_url: "",
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/sign-up`, data, {
          headers: {
            "Content-type": "application/json",
            "Accept-type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            this.setState({
              subStep: 2,
              btnLoading: false,
            });

            if (window.innerWidth < mobileMax) {
              window.scrollTo(0, 0);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 500 || error.response.status === 400) {
            this.setState({
              subStep: 2,
              btnLoading: false,
              wantBlock: "start",
            });
          }
        });
    } else {
      if (!this.state.touchEmail) {
        this.setState({
          touchEmail: true,
          touchPhone: true,
        });
      }
      this.checkValidEmail();
      this.checkValidPhone();
      this.checkValidAgreement();
    }
  }

  wantHadlerButton() {
    this.setState({
      wantButtonLoading: true,
    });

    let data = {
      email: this.state.email,
      phone: this.state.phone,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/add-lead`, data, {
        headers: {
          "Content-type": "application/json",
          "Accept-type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          this.setState({
            wantBlock: "finish",
            wantButtonLoading: false,
          });

          if (window.innerWidth < mobileMax) {
            window.scrollTo(0, 0);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 500 || error.response.status === 400) {
          this.setState({
            wantButtonLoading: false,
          });
          this.props.setErrorText(
            error.response.data.error || "Произошла ошибка"
          );
          this.props.changeNameModal("error");
          this.props.openModal();
        }
      });
  }

  render() {
    return (
      <div className="finishGuide">
        <div
          className={`finishGuide__bg ${this.state.subStep === 2 && "_hidden"}`}
        >
          <div className="finishGuide__bg__item">
            <img
              src={require("../../static/images/finishScreen/list.png")}
              alt=""
            />
          </div>
          <div className="finishGuide__bg__item">
            <img
              src={require("../../static/images/finishScreen/big-phone.png")}
              alt=""
            />
          </div>
          <div className="finishGuide__bg__item">
            <img
              src={require("../../static/images/finishScreen/small-phone.png")}
              alt=""
            />
          </div>
        </div>
        <div
          className={`finishGuide__arrow ${
            this.state.subStep === 1 && "_hide"
          }`}
        >
          <img
            src={require("../../static/images/icons/step-arrow.svg")}
            alt=""
          />
        </div>
        <div className="finishGuide__complete">
          <div
            className={`finishGuide__complete__item ${
              this.state.subStep === 1 && "_active"
            }`}
          >
            <h2>Твоя программа готова!</h2>
            <p>
              Укажи почту и&nbsp;мы&nbsp;отправим тебе персональный план тренировок на&nbsp;неделю. Приходи и&nbsp;активируй свой подарок&nbsp;&mdash; тест-драйв клуба FITRON!
            </p>
          </div>
          <div
            className={`finishGuide__complete__item ${
              this.state.subStep === 2 && "_active"
            }`}
          >
            <h2>
              Желаем удачи и&nbsp;поменьше препятствий на&nbsp;пути
              к&nbsp;отличной форме!
            </h2>
            <p>Тренируйся регулярно и&nbsp;оставайся с&nbsp;FITRON!</p>
          </div>
        </div>
        <div className="finishGuide__data">
          <div
            className={`finishGuide__data__item ${
              this.state.subStep === 1 && "_active"
            }`}
          >
            <div className="finishGuide__form field">
              <div
                className={`field__item
                  ${this.state.email && !this.state.emailError && "_valid"}
                  ${this.state.emailError && "_error"}
                `}
              >
                <input
                  type="text"
                  id="email"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event) => this.onChangeEmail(event)}
                  onFocus={() => this.onFocusEmail()}
                  onBlur={() => this.onBlurEmail()}
                  value={this.state.email}
                  ref={(input) => (this.emailInput = input)}
                />
                <label htmlFor="email">
                  {this.state.emailError
                    ? this.state.emailErrorText
                    : "Твой e-mail"}
                </label>
                <span className="error-field">Пример: example@mail.ru</span>
              </div>
              <div
                className={`
                  field__item _active-phone
                  ${this.state.phone && !this.state.phoneError && "_valid"}
                  ${this.state.phoneError && "_error"}
                `}
              >
                <NumberFormat
                  onValueChange={(values) => this.onChangePhone(values)}
                  onFocus={() => this.onFocusPhone()}
                  onBlur={() => this.onBlurPhone()}
                  format="+7##########"
                  allowEmptyFormatting
                  mask=""
                  id="phone"
                  autoComplete="off"
                  autoCorrect="off"
                  type="tel"
                  displayType="input"
                />
                <label htmlFor="phone">
                  {this.state.phoneError
                    ? this.state.phoneErrorText
                    : "Твой телефон"}
                </label>
                <span className="error-field">{this.state.phoneErrorText}</span>
              </div>
              <div className="field__agreement">
                <label>
                  <input
                    type="checkbox"
                    hidden
                    onClick={() => this.handleAgreement()}
                  />
                  <span
                    className={this.state.agreementError ? "error" : ""}
                  ></span>
                  <p>
                    Нажимая кнопку, ты&nbsp;соглашаешься с&nbsp;
                    <a
                      href="https://fitron.club/policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      политикой обработки персональных данных.
                    </a>
                  </p>
                </label>
                {this.state.agreementError ? (
                  <span className="error-field">
                    {this.state.agreementErrorText}
                  </span>
                ) : null}
              </div>
              <div className="finishGuide__form__btn">
                <button
                  className={`btn btn--send btn__type-4  ${
                    this.state.btnLoading && "_disabled"
                  }`}
                  onClick={() => this.sendFormContacts()}
                >
                  <span>Получить программу</span>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`finishGuide__data__item ${
              this.state.subStep === 2 && "_active"
            }`}
          >
            <div className="finishGuide__share">
              <div className="finishGuide__share__info">
                {/* отправили программу на email */}
                {this.state.subStep === 2 && this.state.wantBlock === null ? (
                  <div className="finishGuide__share__info__complete">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `Проверь почту! Мы&nbsp;выслали тебе программу&nbsp;на <span>${this.state.email}</span>`,
                      }}
                    />
                    <div className="finishGuide__share__btns">
                      <a href="/" className="btn btn__type-1">
                        <span>Собрать еще раз</span>
                      </a>
                      <a
                        href="https://fitron.club"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn__type-3"
                      >
                        <span>Fitron.club</span>
                      </a>
                    </div>
                  </div>
                ) : null}

                {/* если email повторяется, то отправляем заявку (без отправки программы) */}
                {this.state.subStep === 2 && this.state.wantBlock !== null ? (
                  <div className="finishGuide__share__info__want full-block">
                    {this.state.wantBlock === "start" ? (
                      <div className="full-block__start">
                        <h4>Проверь почту!</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `Мы&nbsp;уже отправляли твой план тренировок на&nbsp;почту <span>${this.state.email}</span>`,
                          }}
                        />
                        <p>
                          Если не&nbsp;получил&nbsp;&mdash; проверь
                          &laquo;Спам&raquo; или позвони по&nbsp;телефону{" "}
                          <a
                            href="tel:+78633018000"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            (863) 301-8000
                          </a>
                        </p>
                        <p>
                          Хочешь получить персональную программу тренировок
                          на&nbsp;месяц?
                        </p>
                        <button
                          type="button"
                          className={`btn btn__type-3 ${
                            this.state.wantButtonLoading && "_disabled"
                          }`}
                          onClick={this.wantHadlerButton.bind(this)}
                        >
                          <span>Хочу</span>
                        </button>
                      </div>
                    ) : (
                      <div className="full-block__finish">
                        <p>
                          Заявка отправлена, мы&nbsp;свяжемся с&nbsp;тобой
                          в&nbsp;ближайшее время
                        </p>
                        <a
                          href="https://fitron.club"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn__type-3"
                        >
                          <span>Fitron.club</span>
                        </a>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="finishGuide__share__social">
                <div className="social-share">
                  <span className="share-title">Поделись с друзьями</span>
                  <VKShareButton
                    url={url}
                    title={title}
                    description={description}
                    image={shareImg}
                    className="social-share__item _single"
                  >
                    <img
                      src={require("../../static/images/icons/vk.svg")}
                      alt="vkontakte"
                    />
                  </VKShareButton>
                  {/* <FacebookShareButton
                    url={url}
                    // quote={title}
                    className="social-share__item"
                  >
                    <img
                      src={require("../../static/images/icons/fb.svg")}
                      alt="facebook"
                    />
                  </FacebookShareButton> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { processing } = state;

  return {
    ...processing,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      changeNameModal,
      setErrorText,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Finish);
