import React from 'react';

import './styles/withInfiniteMove.scss';

const WithInfiniteMove = (props) => {
  const { timesToClone, direction, duration } = props;

  return (
    <div className={`withInfiniteMove ${'_moving-' + direction}`}>
      <div className="withInfiniteMove__track" style={{animationDuration: duration + 's'}}>
        {new Array(timesToClone).fill('').map(() => {
          return props.children;
        })}
      </div>
    </div>
  );
};

export default WithInfiniteMove;
