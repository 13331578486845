// ACTION
export const MODAL = {
    OPEN: 'OPEN_MODAL',
    CLOSE: 'CLOSE_MODAL',
    TYPE: 'TYPE_MODAL',
    ERROR_TEXT: 'ERROR_TEXT'
};
  
// ACTION CREATORS
export const openModal = () => {
    document.querySelector('body').classList.add('no-scroll'); // review code
    return { type: MODAL.OPEN }
};

export const closeModal = () => {
    document.querySelector('body').classList.remove('no-scroll'); // review code
    return { type: MODAL.CLOSE }
};
  
export const changeNameModal = (name) => ({
    type: MODAL.TYPE,
    name
});

export const setErrorText = (text) => ({
    type: MODAL.ERROR_TEXT,
    text
});