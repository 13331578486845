import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeStep, setStartInfo } from '../../actions/processing';
import { formOfWord } from '../../utils/formOfWord';

import './styles/stepInfo.scss';

const mobileMax = 699;

class StepInfo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gender: '',
      age: null,
      height: null,
      weight: null,

      genderError: false,
      ageError: false,
      heightError: false,
      weightError: false,

      genderErrorText: '',
      ageErrorText: '',
      heightErrorText: '',
      weightErrorText: '',

      statusBtn: true
    }

    this.ageInput = null;
  }


  componentDidMount() {
    setTimeout(() => {
      if (this.props.step === this.props.dataStep.step) {
        document.querySelector('.js-first-step').classList.add('_activeStep');
      }
    }, 200);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      document.querySelector('.js-first-step').classList.remove('_activeStep');
    }
  }

  setGender(value) {
    this.setState({
      gender: value
    },() => {
      this.ageInput.focus();
      this.checkValidGender();
    })
  }

  onChangeAge(event) {
    this.setState({
      age: +event.target.value
    },() => {
      this.checkValidAge();
    })
  }

  onChangeHeight(event) {
    this.setState({
      height: +event.target.value
    },() => {
      this.checkValidHeight();
    })
  }

  onChangeWeight(event) {
    this.setState({
      weight: +event.target.value
    },() => {
      this.checkValidWeight();
    })
  }

  checkValidGender() {
    if (this.state.gender !== '') {
      this.setState({
        genderError: false,
        genderErrorText: '',
      })
    } else {
      this.setState({
        genderError: true,
        genderErrorText: 'Выберите пол'
      })
    }
  }

  checkValidAge() {
    if (this.state.age > 15 && this.state.age < 102) {
      this.setState({
        ageError: false,
        ageErrorText: '',
      })
    } else if (this.state.age < 16 || this.state.age > 101) {
      this.setState({
        ageError: true,
        ageErrorText: 'Введен некорректный возраст'
      })
    }
  }

  checkValidHeight() {
    if (this.state.height > 99 && this.state.height < 221) {
      this.setState({
        heightError: false,
        heightErrorText: '',
      })
    } else if (this.state.height < 100 || this.state.height > 220) {
      this.setState({
        heightError: true,
        heightErrorText: 'Введен некорректный рост'
      })
    }
  }

  checkValidWeight() {
    if (this.state.weight > 19 && this.state.weight < 201) {
      this.setState({
        weightError: false,
        weightErrorText: '',
      })
    } else if (this.state.weight < 20 || this.state.weight > 200) {
      this.setState({
        weightError: true,
        weightErrorText: 'Введен некорректный вес'
      })
    }
  }

  async nextScreen() {
    if (
      this.state.gender &&
      this.state.age &&
      this.state.height &&
      this.state.weight &&
      !this.state.genderError &&
      !this.state.ageError &&
      !this.state.heightError &&
      !this.state.weightError
    ) {
      await this.props.setStartInfo(this.state.gender, this.state.age, this.state.height, this.state.weight);
      await this.props.changeStep(2);
      
      if (window.innerWidth < mobileMax) {
        window.scrollTo(0, 0);
      }
    } else {
      this.checkValidGender();
      this.checkValidAge();
      this.checkValidHeight();
      this.checkValidWeight();
    }
  }

  render() {

    const {
      step,
      genderData,
      dataStep
    } = this.props;

    return(
      <div className="process__item step js-first-step">
        <div className="step__content _two">

          <div className="step__question">
            <h2>{dataStep.question_title}</h2>
            {/* { dataStep.question_title_desc && <p className="title-desc">{dataStep.question_title_desc}</p> } */}
            <p>{dataStep.question_desc}</p>
          </div>

          <div className="step__main">
            <div className="step__info">
              <div className="info">
                <div className="info__content">
                  <div className="info__block field">

                    <div className={`field__item _radio ${this.state.gender && '_valid'} ${this.state.genderError && '_error'}`}>
                      <label className="_active">Укажи свой пол</label>
                      <div className="field__radio">
                        {
                          genderData.map((el, ind) => (
                            <button
                              key={ind}
                              className={`field__radio__item ${this.state.gender === el.id_str && '_active'}`}
                              onClick={ () => this.setGender(el.id_str) }
                            >
                              <span>{el.title}</span>
                            </button>
                          ))
                        }
                      </div>
                    </div>

                    <div
                      className={`
                        field__item
                        ${this.state.age && !this.state.ageError && '_valid'}
                        ${this.state.ageError && '_error'}`}
                    >
                      <input
                        type="number"
                        id="age"
                        autoComplete="off"
                        onChange={ (event) => this.onChangeAge(event) }
                        ref={(input) => this.ageInput = input } 
                      />
                      <label htmlFor="age">{this.state.ageError ? this.state.ageErrorText : 'Введи свой возраст'}</label>
                      <span className="params-field">{formOfWord(this.state.age,['год','года','лет'])}</span>
                      <span className="error-field">Наши программы рассчитаны на&nbsp;возраст от&nbsp;16&nbsp;до&nbsp;100&nbsp;лет.</span>
                    </div>

                    <div
                      className={`
                        field__item
                        ${this.state.height && !this.state.heightError && '_valid'}
                        ${this.state.heightError && '_error'}`}
                    >
                      <input
                        type="number"
                        id="height"
                        autoComplete="off"
                        onChange={ (event) => this.onChangeHeight(event) }
                      />
                      <label htmlFor="height">{this.state.heightError ? this.state.heightErrorText : 'Введи свой рост'}</label>
                      <span className="params-field">см</span>
                      <span className="error-field">Наши программы рассчитаны на&nbsp;рост от&nbsp;1м до&nbsp;2.2м</span>
                    </div>

                    <div
                      className={`
                        field__item
                        ${this.state.weight && !this.state.weightError && '_valid'}
                        ${this.state.weightError && '_error'}`}
                    >
                      <input
                        type="number"
                        id="weight"
                        autoComplete="off"
                        onChange={ (event) => this.onChangeWeight(event) }
                      />
                      <label htmlFor="weight">{this.state.weightError ? this.state.weightErrorText : 'Введи свой вес'}</label>
                      <span className="params-field">кг</span>
                      <span className="error-field">Наши программы рассчитаны на&nbsp;вес от&nbsp;20кг до&nbsp;200кг</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="step__arrow">
            <img src={ require('../../static/images/icons/step-arrow.svg') } alt=""/>
          </div>

          <div className={`step__next ${this.state.statusBtn && '_show'}`}>
            <div className="container">
              <div className="step__next__counter">
                <span>{`0${step} / 03`}</span>
              </div>
            </div>
            <div className="step__next__btn">
              <button
                type="button"
                className={`btn btn__type-4 ${!this.state.statusBtn && '_disabled'}`}
                onClick={ () => this.nextScreen() }
              >
                <span>Далее</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    processing
  } = state;

  return {
    ...processing
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeStep,
  setStartInfo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepInfo)
