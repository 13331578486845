import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import StepInfo from '../StepInfo';
import StepItem from '../StepItem';

import  { changeStep } from '../../actions/processing';

import './styles/steps.scss';

class Steps extends Component {

  render() {

    const {
      mainData,
      history
    } = this.props;

    return(
      <div className="process">
        {
          mainData.map((el, ind) => {
            if (!el.answers.length && !el.images.length) {
              return(
                <StepInfo
                  key={ind}
                  dataStep={el}
                />
              )
            } else {
              return(
                <StepItem
                  key={ind}
                  dataStep={el}
                  history={history}
                />
              )
            }
          })
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    processing
  } = state;

  return {
    ...processing
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeStep
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Steps)
