import React, {Component} from 'react';
import YouTube from 'react-youtube';

import './styles/training.scss';

export default class Training extends Component {

  constructor(props) {
    super(props);

    this.state = {
      videoLoading: false,
      videoStatus: false
    }

    this.video = null;
  }

  componentDidMount() {
    setTimeout(() => {
      document.querySelector('.training').classList.add('_show');
    }, 200)
  }

  // write videoYT in variables
  readyVideo(e) {
    this.video = e.target;

    setTimeout(() => {
      this.video.playVideo();
    }, 200);

    this.setState({
      videoStatus: true,
      videoLoading: true
    })
  }

  // play video (hide preview)
  playVideo() {
    this.video.playVideo();

    this.setState({
      videoStatus: true
    })
  }

  // stop video (show preview)
  endVideo() {
    this.video.stopVideo();
    
    this.setState({
        videoStatus: false
    })
  }

  render() {
    let videoID = new URL(window.location).searchParams.get('id');
    let videoTitle = new URL(window.location).searchParams.get('title');

    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // autoplay: 1,
        rel: 0,
        showinfo: 0
      }
    };

    return(
      <div className="training">
        <div className="training__content">
          <div className="training__item">
            <div className={`training__item__preview ${this.state.videoStatus ? '_hidden' : ''}`} style={{backgroundImage: `url(https://img.youtube.com/vi/${videoID}/maxresdefault.jpg)`}}>
              {
                this.state.videoLoading && <button type="button" onClick={this.playVideo.bind(this)}></button>
              }
            </div>
            <div className="training__item__video">
              <YouTube 
                videoId={videoID}
                opts={opts}
                onReady={this.readyVideo.bind(this)}
                onEnd={this.endVideo.bind(this)}
              />
            </div>
            {
              videoTitle &&
              <div className="training__item__title">
                <p>{videoTitle}</p>
              </div>  
            } 
          </div>
        </div>
      </div>
    )
  }
}