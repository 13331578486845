import React, {Component} from 'react';

import Header  from '../components/Header';
import Steps from '../components/Steps';

export default class StartScreen extends Component {

  render(){
    return(
      <div className="processingScreen">
        <Header />
        <Steps history={this.props.history} />
      </div>
    )
  }
}
