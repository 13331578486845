import React from 'react';
import { Route, Switch } from 'react-router-dom';

import StartScreen from './containers/StartScreen';
import ProcessingScreen from './containers/ProcessingScreen';
import FinishScreen from './containers/FinishScreen';
import TrainingScreen from './containers/TrainingScreen';
import TrainingsScreen from './containers/TrainingsScreen';
import NotFoundScreen from './containers/NotFoundScreen';

export default () => (
  <Switch>
    <Route exact path="/" component={StartScreen}/>
    <Route path="/processing" component={ProcessingScreen}/>
    <Route path="/finish" component={FinishScreen}/>
    <Route path="/training" component={TrainingScreen}/>
    <Route path="/trainings/:id" component={TrainingsScreen}/>
    <Route component={NotFoundScreen}/>
  </Switch>
);
