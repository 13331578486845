import React, { Component } from 'react';

import Routes from './routes';

class App extends Component {
  
  render() {
    return (
      <Routes />
    )
  }
};

export default App;