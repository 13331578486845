import { MODAL } from '../actions/modal';

export const InitialState = {
  isActive: false,
  nameModal: null,
  errorText: ''
};

// REDUCERS
export default function (state = InitialState, action) {
    switch (action.type) {
        case MODAL.OPEN:
            return Object.assign({}, state, {
                isActive: true
            });
        case MODAL.CLOSE:
            return Object.assign({}, state, {
                isActive: false,
                errorText: ''
            });
        case MODAL.TYPE:
            return Object.assign({}, state, {
                nameModal: action.name
            });
        case MODAL.ERROR_TEXT:
            return Object.assign({}, state, {
                errorText: action.text
            });
        default:
            return state
    }
}